exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-pages-dashboards-am-cs-jsx": () => import("./../../../src/pages/dashboards/AMCs.jsx" /* webpackChunkName: "component---src-pages-dashboards-am-cs-jsx" */),
  "component---src-pages-dashboards-banking-report-jsx": () => import("./../../../src/pages/dashboards/banking-report.jsx" /* webpackChunkName: "component---src-pages-dashboards-banking-report-jsx" */),
  "component---src-pages-dashboards-coal-replacement-jsx": () => import("./../../../src/pages/dashboards/coal-replacement.jsx" /* webpackChunkName: "component---src-pages-dashboards-coal-replacement-jsx" */),
  "component---src-pages-dashboards-index-jsx": () => import("./../../../src/pages/dashboards/index.jsx" /* webpackChunkName: "component---src-pages-dashboards-index-jsx" */),
  "component---src-pages-in-the-news-jsx": () => import("./../../../src/pages/in-the-news.jsx" /* webpackChunkName: "component---src-pages-in-the-news-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-press-releases-jsx": () => import("./../../../src/pages/press-releases.jsx" /* webpackChunkName: "component---src-pages-press-releases-jsx" */),
  "component---src-pages-research-jsx": () => import("./../../../src/pages/research.jsx" /* webpackChunkName: "component---src-pages-research-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-press-release-js": () => import("./../../../src/templates/press-release.js" /* webpackChunkName: "component---src-templates-press-release-js" */)
}

